import { createStore, combineReducers, applyMiddleware } from 'redux'
import {thunk} from 'redux-thunk'

import ClientReducer from './views/client/Reducer'
import PatientReducer from './views/patient/Reducer'


const appReducer = combineReducers({
  client: ClientReducer,
  patient: PatientReducer
})

const rootReducer = (state, action) => {

  if (action.type === 'logout') {
    state = undefined;
  }

  console.log("Last store11",state);
  console.log("action11",action);

  return appReducer(state, action)

}

const store = createStore(rootReducer, applyMiddleware(thunk))
export default store

