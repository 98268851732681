import * as Actions from './ActionTypes'

const initialState = {
    loading: false,
    patientData: [],
    selectedPatient: null,
    error: null,
    totalPage: 0
}

const PatientReducer = (state = initialState, action) => {

    switch (action.type) {
    case Actions.LOADING:
        return {
            ...state,
            loading: true
        }
    case Actions.GET_DATA:
        return {
            ...state,
            patientData: action.payload,
            loading: false
        }
    case Actions.ERROR:
        return {
            ...state,
            loading: false,
            error: action.error
        }
    case Actions.SELECTED_PATIENT:
        return {
            ...state,
            selectedPatient: action.payload,
            loading: false
        }        
    default: return state
    }
}

export default PatientReducer