import * as Actions from './ActionTypes'

const initialState = {
    loading: false,
    clientData: {},
    clientList: [],
    error: null,
    totalPage: 0
}

const ClientReducer = (state = initialState, action) => {

    switch (action.type) {
    case Actions.LOADING:
        return {
            ...state,
            loading: true
        }
    case Actions.GET_DATA:
        return {
            ...state,
            clientData: action.payload,
            loading: false
        }
    case Actions.GET_CLIENTLIST:
        return {
            ...state,
            clientList: action.payload,
            loading: false
        }
    case Actions.ERROR:
        return {
            ...state,
            loading: false,
            error: action.error
        }
    default: return state
    }
}

export default ClientReducer